:root {
  --back: #000000;
  --primary: #ffffff;
  --secondary: #ffffff;
  --highlight: #ffffff;
  --border-width: 2px;
  --border-radius: 0px !important;
  --box-shadow-width: 8px;
  --box-shadow-blur: 16px;

  /* Calculated */
  --black-or-white: #ffffff;
}

.siteContainer {
  width: 100%;
  display: grid;
}

/* home */

.homeContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: calc(100vh - 100px);
  padding: 20px;
}

.homeRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid rgb(234, 234, 234);
  padding: 40px;
  margin-bottom: 40px;
}
.homeRow div {
  width:33%;
}
.homeRow img {
  max-height: 10vw;
}
.homeRow h3 {
  font-size: 2vw;
  font-weight: bold;
  margin:0px;
  padding:0px;
  margin-right:20%;
}
.homeRow .button {
  border: 1px solid #fff;
  color: #fff;
  background-color: #000;
  padding: 20px;
  font-size: 1.25vw;
  cursor: pointer;
}
.homeRow a.button img {
  border: none !important;
}

.homeRow .button:hover {
  background-color: #fff;
  border: 1px solid #fff !important;
  color: #000;
  cursor: pointer;
}

.black-or-white {
  color: var(--black-or-white);
}

body {
  background-color: var(--back);
  color: var(--secondary);
}

body {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
  letter-spacing: 0.1em;
  font-size: 13px;
}

.header,
.header a {
  font-family: "Orbitron", "Space Mono", monospace;
  font-weight: 600;
  color: var(--black-or-white);
}
.header a:hover {
  color: var(--primary);
}

.numeric-value,
.nft-card-header-item-value,
.bar-graph-text-right {
  font-family: "Fira Code";
}

div {
  border-radius: var(--border-radius);
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.loading {
  display: inline-block;
  width: 32px;
  height: 32px;
  font-size: 32px;
  line-height: 32px;
  animation: rotation 2s linear infinite;
}

.bar-graph {
  border: 1px solid var(--primary);
  border-radius: 0;
}
.bar-graph-background {
  background: var(--primary);
  opacity: 0.5;
  border-radius: 0;
}
.bar-graph-text,
.bar-graph-text-right {
  color: var(--highlight);
}

a {
  color: var(--primary);
}

a:hover {
  color: var(--highlight);
  cursor: hand;
}

.link:hover {
  cursor: hand;
}

.hover.link:hover {
  color: var(--highlight);
  cursor: hand;
}

.App {
  text-align: center;
  padding: 10px;
}

.panel-container {
}

.panel-project-info {
  margin: 10px;
}

.panel-nft-list {
}

.panel-trait-types {
}

@media (min-width: 600px) {
  .panel-container {
    display: grid;
    grid-gap: 1em;
    grid-template-columns: 1fr 6fr;
  }

  .panel-project-info {
    grid-row: 1;
    grid-column: 1 / span 2;
  }

  .panel-trait-types {
    grid-row: 2;
    grid-column: 1;
  }

  .panel-nft-list {
    grid-row: 2;
    grid-column: 2;
  }
}



.project-info {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
}
.project-info-image {
  text-align: left;
}
.project-info-image img {
  max-width: 222px;
}
.project-info-title {
  font-size: 1.6em;
}
.project-info-description {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-height: 150px;
  padding: 4px;
  text-overflow: ellipsis;
}
.project-info-link {
  word-break: break-all;
}



.nft-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  grid-gap: 0;
  grid-auto-flow: row dense;
}

.nft-card .project-info {
  min-height: 240px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nft-container-single {
  margin: auto;
  max-width: 400px;
}

.nft-card {
  margin: 16px;
  border: var(--border-width) solid var(--primary);
  background: var(--back);
  line-height: 23px;
}

.nft-card:hover {
  border: var(--border-width) solid var(--secondary);
}

.hover-popup {
  border: var(--border-width) solid var(--primary);
  box-shadow: var(--box-shadow-width) var(--box-shadow-width) var(--box-shadow-blur) 0px var(--secondary);
  /* box-shadow: 10px 5px 5px red; */

  background: var(--back);
}

.nft-card-header {
  display: grid;
  grid-template-columns: repeat(4, 1fr);

  background: var(--primary);
  color: var(--back);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

  text-align: right;
}

.nft-card-header-token-id {
  text-align: left;
  grid-column: span 4;
  grid-row: span 2;
}

.nft-card-header-title {
  grid-column: span 4;
  grid-row: span 2;
}

.nft-card-header-item {
  grid-column: span 2;
  grid-row: span 2;
  padding: 4px;

  display: flex;
  flex-direction: column;
}
.nft-card-header-item-2 {
  grid-column: span 2;
  grid-row: span 3;
  padding: 4px;

  display: flex;
  flex-direction: column;
}

.nft-card-header-item-label {
}
.nft-card-header-item-value {
  flex: 1;
  background: var(--back);
  color: var(--highlight);
  border-radius: 0;
  padding: 4px;
}

.nft-card-content {
  padding: 16px;
}

.nft-card-image {
  /* width: 150px; */
  /* height: 150px; */
  margin: auto;
}
.nft-card-image img {
  /* width: 150px; */
  height: 300px;
  object-fit: contain;
}

.nft-card-stats {
  padding: 4px;
}

.nft-card-stats-row {
  display: flex;
  flex-direction: row;
}

.nft-card-stats-cell {
  flex: 1;
  padding: 1px;
}

.nft-card-stats-cell.link:hover {
  color: var(--highlight);
}

.nft-card-stats-title {
  color: var(--highlight);
}

.nft-trait-types-header {
  margin: 8px;
  border: var(--border-width) solid var(--secondary);
}
.nft-trait-types-header-expandable {
  display: block;
}
.nft-trait-types-header-simple {
  display: none;
}

/* @media (min-width: 600px) {
  .nft-trait-types-header-expandable {
    display: none;
  }
  .nft-trait-types-header-simple {
    display: block;
  }
} */

.nft-trait-types {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  grid-gap: 1em 1em;
  grid-auto-flow: row dense;
}

.nft-trait-types .bar-graph {
  border: 0;
}

.nft-trait-type {
  margin: 8px;
  border: var(--border-width) solid var(--secondary);
}

.nft-trait-type-header {
  padding: 4px;
}

.nft-trait-values {
  display: flex;
  flex-direction: column;
}

.nft-trait-value {
  padding: 4px;
  opacity: 0.5;
}
.nft-trait-value.link:hover {
  opacity: 1;
  padding: 3px;
  border: 1px solid var(--secondary);
}
.nft-trait-value-selected {
  opacity: 1;
  padding: 3px;
  border: 1px solid var(--secondary);
}

.clickable-section {
  cursor: pointer;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 5px;
  transition: 0.3s;
  margin-top: 10px;
}

.clickable-section:hover {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}

.nft-card-image-row {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 10px;
  padding: 15px 0px 0px 0px;
}

.nft-card-image-row img {
  width: 50px;
  height: 50px;
  margin-right: 5px;
}

.clippedText {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3; /* Number of lines to display */
  overflow: hidden;
  max-height: 150px;
  line-height: 22px;
}

.project-list {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}
.projectRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.project-row {
  flex: 1 0 100%;
  display: flex;
  justify-content: space-between;
}
.projectRowItem {
  width:50%;
  text-align: center;
  margin-right: 10%;;
}
.projectRowItem h2 {
  margin-bottom: 20px;
  line-height: 23px;
}
.projectRowImage {
  width: 50%;
  text-align: left;
  line-height: 0;
}
.projectRowImage img {
  max-width: 250px;
}

@media (max-width: 800px) {
  .hideMobile {
    display: none;
  }
}

@media (max-width: 700px) {
  .project-info {
    flex-direction: row;
  }
  .header, .header a {
    font-size:20px;
  }
  .homeRow h3 {
    font-size: 18px;
  }
  .clippedText {
    display: none;
  }
  .projectRowItem {
    width:50%;
    text-align: center;
    margin-right: 0%;
  }
  .projectRowItem h2 {
    line-height: 40px;
  }
}

@media (max-width: 600px) {
  .project-info {
    flex-direction: row;
  }
  .homeRow h3 {
    font-size: 18px;
  }
  .projectRowImage img {
    max-width: 200px;
  }
}

@media (max-width: 599px) {
  .project-info-image {
    margin-top: 15px;
  }
  .header,
  .header a {
    font-size: 15px;
  }
  .nft-card {
    margin: 15px 0px 0px 0px;
  }
  .header, .header a {
    font-size:10px;
  }
  .homeRow h3 {
    font-size: 14px;
  }
  .hideMobile {
    display: none;
  }
}

@media (max-width: 400px) {
  .projectRowImage img {
    max-width: 150px;
  }
  .projectRowItem h2 {
    line-height: 40px;
  }
  .projectRowItem {
    width:50%;
    text-align: center;
    margin-right: 5%;
  }
}

@media (max-width: 320px) {
  .header, .header a {
    font-size:10px;
  }
  .homeRow h3 {
    font-size: 14px;
  }
  .projectRowItem h2 {
    line-height: 40px;
  }
  .hideMobile {
    display: none;
  }
}